const loanStatuses = [
  "submitted", // quote is submitted by borrower
  // UPDATE, no longer using this status // "qualified", // quote has been reviewed by Loan Admin and terms have been updated based on capital provider guidelines
  "reviewed", // quote has been reviewed with borrower, awaiting intent to proceed
  "processing", // borrower confirmed intent to proceed, loan has been assigned to a capital provider
  "underwriting", // complete file, has been provided to underwriter
  "committee", // complete file has been provided to loan committee for final approval
  "approved", // loan committee approved with terms confirmed
  "funded", //  settlement has occured and loan has been disbursed
  "boarded", // loan has been assigned to a servicer
  "payoff", // loan has been paid off by borrower
  //
  "hold", // loan has been put on hold because borrower is not or can't move forward through our system at this time. 10 day hold before retracted.
  "retracted", // borrower didnt want to move forward
  "declined", // capital provider or offermarket refused to process
  "deleted", // borrower deleted in UI, my/loans
  "referred", // a loan that was referred out to a third party lender/capital provider and needs a follow up to make sure we get paid for the referral
  // once loan is issued and OM is funder
  "servicing", // loan is being serviced by OM  (OM is servicer)
];

export default loanStatuses;
